import Layout from '@components/layout/Layout';
import Container from '@components/ui/container/Container';
import Footer from '@components/ui/footer/Footer';
import Header from '@components/ui/header/Header';
import Partner from '@components/ui/partners/Partners';
import TitleBar from '@components/ui/title-bar/TitleBar';
import { LibraryIcon } from '@heroicons/react/solid';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { StaticImage } from 'gatsby-plugin-image';
import LanguageProvider from 'lib/language.provider';
import React from 'react';

const PartnerzyPage = ({ pageContext, location }) => {
	i18n.activate(pageContext.language);

	return (
		<LanguageProvider>
			<Layout>
				<main className="min-h-screen">
					<Header location={location.pathname} />
					<div className="flex flex-col min-h-screen">
						<TitleBar>
							<div className="relative z-10 pt-10 w-full text-white md:max-w-xl lg:max-w-2xl">
								<h1 className="font-serif text-4xl font-bold">
									<Trans>Partnerzy</Trans>
								</h1>
							</div>
							<div className="absolute top-0 left-0 w-full h-full">
								<StaticImage
									className="block object-cover w-full h-full"
									src="../assets/images/partnerzy-photo-01.jpg"
									alt={t`Partnerzy`}
									placeholder={'none'}
								/>
							</div>
						</TitleBar>
						<div>
							<Container>
								<div className="grid grid-cols-1 gap-4 py-8 md:grid-cols-2 md:py-12 md:gap-8">
									<Partner
										icon={<LibraryIcon className="mb-8 w-12" />}
										title={t`Radca Prawny Sebastian Taczkowski `}
										subtitle={t`Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.`}
									/>
									<Partner
										icon={<LibraryIcon className="mb-8 w-12" />}
										title={t`Adwokat Piotr Malach `}
										subtitle={t`Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.`}
									/>
								</div>
							</Container>
						</div>
						<Footer />
					</div>
				</main>
			</Layout>
		</LanguageProvider>
	);
};

export default PartnerzyPage;
