import React, { ReactNode } from 'react';
import { FC } from 'react';

type PartnerProps = {
	icon: ReactNode;
	title: string;
	subtitle: string;
};

const Partner: FC<PartnerProps> = ({ icon, title, subtitle }) => {
	return (
		<>
			<div className="p-10 text-gray-dark bg-gray-light">
				{icon}
				<h3 className="font-serif font-bold text-xl mb-5">{title}</h3>
				<p className="mb-8">{subtitle}</p>
			</div>
		</>
	);
};

export default Partner;
